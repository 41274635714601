/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment } from 'react';
import {Row, Link, Hero, Features, Testimonial, Content} from 'components/lib';
import { RemovePii } from 'views/website/remove-pii';
import {RowHeader} from "../../components/layout/row/header";

export function Home(props) {

  return(
    <Fragment>
        <Hero
            title="Remove PII / PHI"
            tagline="Remove and replace protected data in any CSV: Open Source and Offline."
            />

        <RemovePii />

        <Row title='Features' color='white'>
            <Features />
        </Row>

    </Fragment>
  );
}
