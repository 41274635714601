/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import React from 'react';
import { Animate, Row, Content, Link } from 'components/lib'
import Style from './footer.module.scss';

export function Footer(props){

  return (
    <Animate type='slideup'>
      <footer className={ Style.footer }>
        <Row>
          <Content>

            <nav>
            <Link url='/' text='Home'/>
            {/*<Link url='/pricing' text='Pricing'/>*/}
            {/*<Link url='/signin' text='Sign in'/>*/}
            {/*<Link url='/signup' text='Sign up'/>*/}
            <Link url='/terms' text='Terms'/>
            <Link url='/privacy' text='Privacy'/>
            <Link url='https://dba.sh/contact' text='Contact'/>
            </nav>

            <span>{ `Copyright © dba.sh ${new Date().getFullYear()}`}</span>
            <address>dba.sh - The SecOps Platform</address>

          </Content>
        </Row>
      </footer>
    </Animate>
  );
}
