import { Home } from 'views/website/home';
import { FAQ } from 'views/website/faq';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'home',
    title: "Remove PII / PHI"
  },
  {
    path: '/faq',
    view: FAQ,
    layout: 'home',
    title: "FAQ"
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy'
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Terms & Conditions'
  },
]

export default Routes;