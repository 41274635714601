import React from 'react';
import { Button, Badge } from 'components/lib';
import Style from './table.module.scss';

export function Body(props){

  if (props.data?.length){
    return (
      <tbody>
        { props.data.map((row, index) => {
          return (
            <Row
              key={ index }
              data={ row }
              show={ props.show }
              hide={ props.hide }
              badge={ props.badge }
              header={ props.header }
              actions={ props.actions }
            />
          );
      })}
      </tbody>
    );
  }

  return (
    <tbody>
      <tr>
        <td colSpan='10'>No results found</td>
      </tr>
    </tbody>
  );
}

export function Row(props){

  let row = {...props.data }
  row.actions = props.actions;
  const hasActions = Object.values(row.actions).some(x => (x !== undefined));
  const hasOwnActions = (props?.data?.actions) ? Object.values(props.data.actions).some(x => (x !== undefined)) : false;
  let columnOrder = Object.keys(row);
  // This handles overriding the column order if the header override is set
  if (props.header) {
    columnOrder = {};
    for (let item of props.header) {
      columnOrder[item.name] = true;
    }
    if (props.actions) {
      columnOrder.actions = true;
    }
  }
  return(
    <tr data-id={ props.data.id }>
      { Object.keys(columnOrder).map((cell, index) => {
        // actions
        if (cell === 'actions'){
          if (hasActions || hasOwnActions){
            let actionRow = (hasOwnActions) ? props.data.actions : row.actions;
            return (
              <td key={ index } className={ Style.actions }>
              { actionRow?.custom?.map((action, i) => {

                 return <Button key={ i } icon={ action.icon } action={ () => action.action(row) } />

                })}
                { actionRow.edit &&
                  <Button icon='edit' action={ () => actionRow.edit(row) }/> }
                  
                { actionRow.invite &&
                <Button icon='send' action={ () => actionRow.invite(row) } /> }

                { actionRow.email &&
                  <Button icon='mail' action={ () => window.location = `mailto:${row.email}` }/>}

                { actionRow.delete &&
                <Button icon='trash' action={ () => actionRow.delete(row) } /> }
              </td>
            );
          }
          else return false;

        }

        // hide
        if (props.hide?.includes(cell))
          return false;

        // show
        if (props.show && !props.show.includes(cell))
          return false;

        let value = row[cell];

        if (value instanceof Date) {
          value = value.toLocaleString();
        } else if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value)) {
          value = new Date(value).toISOString().split('T')[0];
        }

        // has badge
        let badgeColor;
        if (props.badge && cell === props.badge.col && typeof value !== 'undefined'){

          badgeColor = props.badge.color;
          for (let condition of Object.values(props.badge.condition || {})) {
            if (condition.value === value) { badgeColor = condition.color; }
          }

        }

        let returnText = {
          text: value === true  ? 'Yes' : (value === false ? 'No' : value)
        };

        // if (typeof returnText.text === "object") {
        //   debugger;
        //   returnText.text = returnText.text.toString();
        // }

        return(
          <td key={ index }>
            { badgeColor ? <Badge { ...returnText } color={ badgeColor } /> : returnText.text }
          </td>
        );
      })}
    </tr>
  );
}
