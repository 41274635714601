let strategies = {};
export default function engine(faker, userConfig) {
  if (!faker) {
    // faker = require('faker');
  }
  let config = {};
  let trackedColumns = {};
  let hasHeader = false;
  if (userConfig.strategies) {
    strategies = userConfig.strategies;
  }

  function handleHeader(data) {
    for (let [dataKey, dataValue] of Object.entries(data)) {
      for (let configKey of Object.keys(config)) {
        if (dataValue === configKey) {
          config[dataKey] = { ...config[configKey] }
          trackedColumns[dataKey] = {};
          delete config[configKey];
          delete trackedColumns[configKey];
          break;
        }
      }

    }
  }

  userConfig.columns.forEach((columnConfig) => {
    if (columnConfig.columnKey) {
      hasHeader = true;
    }

    let key = typeof columnConfig.columnNum == 'number' ? columnConfig.columnNum : columnConfig.columnKey;
    let strategy;

    // Custom user strategy from strategies.js
    if (strategies[columnConfig.redactWith]) {
      strategy = strategies[columnConfig.redactWith];
    }

    // Faker template
    if (!strategy && columnConfig.redactWith.includes('{{')) {
      try {
        faker.fake(columnConfig.redactWith);
        strategy = () => { return faker.fake(columnConfig.redactWith); }
      } catch (_e) { }
    }

    // Faker function
    if (!strategy && columnConfig.redactWith.includes('.')) {
      let fakerPieces = columnConfig.redactWith.split('.');
      let fakerFunction = faker;
      fakerPieces.forEach((piece) => {
        if (!fakerFunction[piece]) {
          fakerFunction = null;
          return;
        }
        fakerFunction = fakerFunction[piece]
      });

      if (fakerFunction) {
        strategy = fakerFunction;
      }
    }

    // Raw value
    if (!strategy) {
      strategy = () => { return columnConfig.redactWith; }
    }

    config[key] = {
      strategy,
      tracked: columnConfig.tracked,
    };

  });

  for (let key of Object.keys(config)) {
    trackedColumns[key] = {};
  }

  return {
    process: function (data) {
      if (hasHeader) {
        handleHeader(data);
        hasHeader = false;
        return data;
      }

      for (let [key, dataValue] of Object.entries(data)) {
        if (dataValue === "" || dataValue === null) continue;

        if (config[key]) {
          let replacement = config[key].strategy();

          if (config[key].tracked) {
            if (trackedColumns[key][dataValue]) {
              replacement = trackedColumns[key][dataValue];
            } else {
              trackedColumns[key][dataValue] = replacement;
            }
          }

          data[key] = replacement;
        }
      }
      return data;
    }
  }

};