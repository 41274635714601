import Axios from 'axios';

export async function APIState(setState, url, formatter, method){

  try {

    if (!url){
      setState({ data: null, loading: false });
      return;
    }

    setState({ loading: true });
    const res = await Axios({
      url: localStorage.getItem('apiPath') + url,
      method: method || 'get',
    })

    if (formatter && res?.data?.data) {
      res.data.data = formatter(res.data.data);
    }

    setState({ data: res?.data?.data, loading: false });

  } catch (err){
    console.error(err);
  }

}
