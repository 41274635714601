/***
*
*   USERS
*   Enables an admin to manage the users in their application
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { ViewContext, Card, AccountNav, Table,
  Button, Animate, usePermissions, APIState } from 'components/lib';

export function Users(props){

  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const [users, setUsers] = useState({ data: null, loading: false });
  const refreshData = () => APIState(setUsers,  '/api/account/users', formatUserList);

  function invite(){

    context.modal.show({
      title: 'Add User',
      form: {
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        },
        permission: {
          label: 'Permission',
          type: 'select',
          default: 'user',
          options: permissions?.data?.list?.filter(x => x.value !== 'owner') 
        },
      },
      buttonText: 'Send Invite',
      text: 'To invite more than one user, separate the emails with a comma',
      url: '/api/invite',
      method: 'POST',

      }, (form, res) => {
        refreshData();
    });
  }

  function editUser(data, callback){

    context.modal.show({
      title: 'Update User',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        },
        name: {
          label: 'Name',
          type: 'text',
          required: true,
          value: data.name,
          errorMessage: 'Please enter a name'
        },
        email: {
          label: 'Email',
          type: 'email',
          value: data.email,
          required: true,
        },
        permission: {
          label: 'Permission',
          type: data.permission === 'owner' ? null : 'select',
          options: permissions?.data?.list?.filter(x => x.value !== 'owner') ,
          default: data.permission
        }
      },
      buttonText: 'Save',
      url: '/api/user',
      method: 'PATCH'

    }, (res) => {
      context.notification.show(data.name + ' was updated', 'success', true);
      refreshData();
      callback(res);
    });
  }

  function deleteUser(data, callback){

    context.modal.show({
      title: 'Delete User',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete User',
      text: `Are you sure you want to delete ${data.name}?`,
      url: '/api/user',
      method: 'DELETE'

    }, () => {
      context.notification.show(data.name + ' was deleted', 'success', true);
      refreshData();
      callback();
    });
  }

  function deleteInvite(data, callback){
    
    context.modal.show({
      title: 'Delete Invite',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete Invite',
      text: `Are you sure you want to delete the invite for ${data.email}?`,
      url: '/api/invite',
      method: 'DELETE'

    }, () => {
      context.notification.show(`${data.email}'s invite was deleted`, 'success', true);
      refreshData();
      callback();
    });
  }

  async function resendInvite(data){
    try {

      await axios({ 
        
        url: '/api/invite',
        method: 'post',
        data: { email: data.email }
      
      });
      
      context.notification.show(`Invite re-sent to ${data.email}`, 'success', true);

    }
    catch(err){
      context.handleError(err);
    }
  }

  function formatUserList(userData) {
    let list = [];

    if (userData?.users?.length){
      userData.users.forEach(x => {
        list.push({

          id: x.id,
          name: x.name,
          email: x.email,
          date_created: x.date_created,
          permission: x.permission,
          status: 'Registered'

        });
      });
    }

    if (userData?.invites?.length){
      userData.invites.forEach(x => {
        list.push({

          id: x.id,
          name: '',
          email: x.email,
          date_created: x.date_sent,
          permission: x.permission || 'user',
          status: 'Invited',
          actions: {
            invite: resendInvite,
            delete: deleteInvite
          }

        });
      });
    }

    return list;
  }
  
  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Fragment>

      <AccountNav />
      <Animate>

        <section className='title-row'>
          <h2>Manage Users</h2>
          <Button small text='Add User' action={ invite }/>
        </section>

        <Card>
          <Table
            search
            className='restrict-width'
            data={ users?.data }
            loading={ users?.loading }
            show={['email', 'name', 'date_created', 'last_login', 'permission', 'status']}
            badge={{ col: 'status', color: 'blue', condition: [
              { value: 'Registered', color: 'green' },
            ]}}
            actions={{ edit: editUser, delete: deleteUser, email: true }}
          />
        </Card>

      </Animate>
    </Fragment>
  );
}
