/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React from 'react';
import { Card, Animate, Feedback, useAPI } from 'components/lib';

export function Dashboard() {

    const reports = useAPI('/api/metabase');
    let reportCards = [];

    if (reports.data) {
        {reports.data.forEach( report => {
            reportCards.push(
                <div style={{ width: "50%", minWidth: "500px", minHeight: "360px", position: "relative", overflow: "hidden", paddingTop: "25%", }} >
                    <iframe style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: 0, }}
                        src={report}
                        frameBorder={0}
                        allowTransparency
                    />
                </div>
            )
        })}
    }

    return (
        <Animate type='pop'>
            <Card>
                <div style={{
                    // padding: 20,
                    display: "flex",
                    flexFlow: "row wrap",
                    // width: "80vw",
                }}>
                    {reportCards}
                </div>
            </Card>
        <Feedback />
        </Animate>
    );
}
