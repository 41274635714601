import { Profile } from 'views/account/profile';
import { Password } from 'views/account/password';
import { Billing } from 'views/account/billing';
import { Upgrade } from 'views/account/upgrade';
import { Users } from 'views/account/users';
import { Domains } from 'views/account/domains';
import { SSO } from 'views/account/sso';


const Routes = [
  {
    path: '/account',
    view: Profile,
    layout: 'app',
    permission: 'user',
    title: 'Your Profile'
  },
  {
    path: '/account/password',
    view: Password,
    layout: 'app',
    permission: 'user',
    title: 'Your Password'
  },
  {
    path: '/account/billing',
    view: Billing,
    layout: 'app',
    permission: 'owner',
    title: 'Billing'
  },
  {
    path: '/account/upgrade',
    view: Upgrade,
    layout: 'app',
    permission: 'user',
    title: 'Upgrade'
  },
  {
    path: '/account/users',
    view: Users,
    layout: 'app',
    permission: 'admin',
    title: 'Users'
  },
  {
    path: '/account/sso',
    view: SSO,
    layout: 'app',
    permission: 'admin',
    title: 'SSO - Setup'
  },
  {
    path: '/account/domains',
    view: Domains,
    layout: 'app',
    permission: 'admin',
    title: 'Domains'
  },
]

export default Routes;