/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import Style from './logo.module.scss';

export function Logo(props){

  const css = ClassNames([

    Style.logo,
    props.className

  ]);

  return(
    <div url='/' className={ css } style={ props.style }>bash</div>
  )
}
