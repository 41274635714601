import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  ViewContext, Card, Table,
  Form, APIState, Feedback
} from 'components/lib';

export function Filter() {

  const context = useContext(ViewContext);
  const [filters, setFilters] = useState({ data: null, loading: false });
  const refreshData = () => APIState(setFilters,  '/api/filter');

  function deleteFilter(data, callback){
    context.modal.show({
      title: 'Delete Filter?',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete Filter',
      text: 'Are you sure you want to delete ' + data.url + '-' + data.selector + '?',
      url: '/api/filter/' + data.id,
      method: 'DELETE'

    }, () => {

      context.notification.show(data.name + ' was deleted', 'success', true);
      refreshData();
      callback();

    });
  }

  let validTypes = [];
  let types = ['secret', 'PHI', 'protected', 'PII', 'key', 'password', 'credit card', 'attorney client privileged', 'CUI', 'SSN', 'financial', 'unspecified'].sort();
  for (let type of types) {
    validTypes.push({
      value: type,
      label: type
    });
  }
  let options = [{label:'Confirm copy with user', value: 'confirm'}, {label: 'Do not allow copying', value: 'deny'}, {label: 'Allow copying', value: 'allow'}];
  let config = {
    name: {
      label: 'Name',
      type: 'text',
      required: true,
      value: '',
      errorMessage: 'Please enter a filter name',
    },
    url: {
      label: 'URL',
      type: 'text',
      required: true,
      value: '',
      errorMessage: 'Please enter a valid URL',
    },
    selector: {
      label: 'CSS Selector',
      type: 'text',
      required: true,
      value: '',
      errorMessage: 'Please enter a valid selector'
    },
    blur: {
      label: 'Redact content to hide it from screen shots?',
      type: 'switch',
      value: false,
      errorMessage: 'Please choose a value'
    },
    copyRule: {
      label: 'Allow users to copy information contained in this selector?',
      type: 'select',
      required: true,
      value: options[0].value,
      options: options,
      errorMessage: 'Please enter a valid selector'
    },
    type: {
      label: 'Classification',
      type: 'select',
      required: true,
      options: validTypes,
      errorMessage: 'Please choose a classification'
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  return(
    <Fragment>

      <Card
        title='Create a new filter'
        loading={ filters?.loading } widerWidth>

        { filters?.data &&
        <Form
          buttonText='Save'
          url={'/api/filter'}
          method='POST'
          data={config}
          callback={() => { refreshData(); }}
          class='widerWidth'
        />
        }
      </Card>

      <Card>
        <h1>Existing Rules </h1>

        <Table
          search
          className='restrict-width'
          data={ filters?.data }
          loading={ filters?.loading }
          show={['name', 'url', 'selector', 'type', 'blur', 'on_copy', 'created_at']}
          overrideHeader={true}
          badge={{ col: 'permission', color: 'blue' }}
          actions={{ delete: deleteFilter }}
        />
      </Card>
      <Feedback />
    </Fragment>

  );
}
