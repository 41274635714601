/***
*
*   TERMS & CONDITIONS
*   Boilerplate terms & conditions – replace the values
*   inside [] with your own text
*
**********/

import React from 'react';
import Faq from 'react-faq-component';
import { Article } from 'components/lib';
const data = {
  rows: [

    {
      title: "What is the maximum file size that can be processed?",
      content: "Maximum file size is limited by your machine memory, we recommend keeping files below 1 gigabyte"
    },
    {
      title: "What file types are supported?",
      content: "Currently only CSVs are supported on this site, to work with Excel files use <a href='https://www.npmjs.com/package/redact-phi'>NPM - Redact PHI</a>"
    },
    {
      title: "Do you have an example for testing?",
      content: "<a href='https://gitlab.com/dbash-public/redact-phi/-/raw/main/example/remove_pii_demo.csv?inline=false'>Download CSV example</a>"
    },
    {
      title: "What do I use the strategy download for?",
      content: "Currently strategy files are only usable with the command line version of this tool: <a href='https://www.npmjs.com/package/redact-phi'>NPM - Redact PHI</a>"
    },
    {
      title: "Where is my data going?",
      content: "We do not send your data to any servers, we use client side processing to redact the information using only your computer."
    },
    {
      title: "What is the Preserve toggle?",
      content: "Enabling \"Preserve\" on a column causes repeated data to be replaced with the same fake data.  This allows you to preserve data relationships while generating fake data."
    },
    {
      title: "I still don't trust you...",
      content: "This software is available as a convenient to use command line tool <a href='https://www.npmjs.com/package/redact-phi'>NPM - Redact PHI</a>"
    },
    {
      title: "I'd like to host this site myself?",
      content: "We offer this page as a self-hosted solution, <a href='https://dba.sh/contact'>Contact Us</a>"
    },
  ]
}
export function FAQ(){

  return(

    <Article>

      <h1>FAQ</h1>

      <div>
        <Faq data={data}/>
      </div>

    </Article>
  )
}
