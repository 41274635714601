/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, ViewContext, Form, Card,
  AccountNav, Button, History, Animate, useAPI } from '../../components/lib';

export function SSO(props){

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const sso = useAPI('/api/sso');

  // function closeAccount(){
  //   viewContext.modal.show({
  //
  //     title: 'Close Your Account',
  //     form: closeAccount,
  //     buttonText: 'Close Account',
  //     url: '/api/account',
  //     method: 'DELETE',
  //     text: 'Are you sure you want to delete your account? ' +
  //       'You will lose all of your data and this can not be undone.',
  //
  //   }, () => {
  //
  //     // destory user
  //     localStorage.clear();
  //     History.push('/signup');
  //    entity_id: "dbash",
  //     certificate: fs.readFileSync("./dbash.cer").toString(),
  //     assert_endpoint: "http://localhost:3000/api/saml/test",
  //     allow_unencrypted_assertion: true
  //   });
  // sso_login_url: "https://login.microsoftonline.com/f2dcee4d-1c33-4fa0-bcbb-46e802115360/saml2",
  //   sso_logout_url: "https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0",
  //   certificates: [fs.readFileSync('./dbash.cer').toString()],
  // }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card
          title='Configure SSO SP'
          loading={ sso.loading }>

          { sso?.data &&
          <Form
            buttonText='Save'
            url='/api/sso/sp'
            method='PATCH'
            data={{
              enabled: {
                label: 'Enabled',
                type: 'switch',
                value: sso.data.enabled || false,
              },
              entityId: {
                label: 'Entity ID',
                type: 'text',
                required: true,
                value: sso.data.entity_id,
                errorMessage: 'Please enter an entity id'
              },
              assertEndpoint: {
                label: 'Assertion Endpoint',
                type: 'text',
                required: true,
                value: sso.data.assertion_endpoint,
                errorMessage: 'Please enter the assertion endpoint'
              },
              encryptedAssertion: {
                label: 'Encrypted Assertions?',
                type: 'switch',
                value: sso.data.encrypted_assertions || false,
                errorMessage: 'Selectg whether assertions are encrypted or not'
              },

              certificate: {
                label: 'Base64 Certificate',
                type: 'text',
                required: true,
                value: sso.data.sp_base64_certificate,
                errorMessage: 'Please a valid base64 encoded certificate'
              }
            }}
            callback={ res => {console.log(res)}}
          />
          }
        </Card>
        <Animate>
          <Card
            title='Configure SSO IDP'
            loading={ sso.loading }>

            { sso?.data &&
            <Form
              buttonText='Save'
              url='/api/sso/idp'
              method='PATCH'
              data={{
                ssoLoginUrl: {
                  label: 'SSO Login URL',
                  type: 'text',
                  required: true,
                  value: sso.data.idp_sso_login_url,
                  errorMessage: 'Please enter the SSO login URL'
                },
                ssoLogoutUrl: {
                  label: 'SSO Logout URL',
                  type: 'text',
                  required: true,
                  value: sso.data.idp_sso_logout_url,
                  errorMessage: 'Please enter your SSO logout url'
                },
                certificate: {
                  label: 'Certificate (may be the same as above)',
                  type: 'text',
                  required: true,
                  value: sso.data.idp_base64_certificate,
                  errorMessage: 'Please enter a certificate'
                },
              }}
              callback={ res => authContext.update({ name: res.data.data.name })}
            />
            }
          </Card>

        </Animate>
      </Animate>
    </Fragment>
  );
}
