/***
*
*   TESTIMONIAL
*   Create a testimonial with a quote, author image and name
*
*   PROPS
*   text: the quotation
*   author: name of the author (optional)
*   image - imported image object (optional)
*
**********/

import React from 'react';
import { Image } from 'components/lib';
import Style from './testimonial.module.scss';

export function Testimonial(props){

  return(
    <div className={ Style.testimonial }>

      { props.image &&
        <Image
          source={ props.image }
          alt={ props.author }
          style={{
            width: "150px",
            objectFit: "cover",
            objectPosition: "50% 50%",
            height: "125px",
          }}
        />
      }

      <blockquote>

        "{ props.text }"
         <cite>– <a href={props.link} style={{color: "#68c5fa"}}>{props.author}</a></cite>

      </blockquote>

    </div>
  );
}
