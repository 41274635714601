import React, { Fragment } from 'react';
import {
  Feedback,
  MetabaseAppEmbed
} from 'components/lib';

export function Reporting() {

  return(
    <Fragment>

    <MetabaseAppEmbed
      base={"http://dbash.net:3001"}
      path={"/"}
    >

    </MetabaseAppEmbed>
    <Feedback />

    </Fragment>

  );
}
