import { useState, useContext, useEffect } from 'react';
import { Button, Row, Switch, Table, ViewContext } from 'components/lib';
import ReactGA from 'react-ga';
import faker from 'faker';
export function RemovePiiStrategy(props) {
  ReactGA.initialize('G-BH07XGSWPG');

  const context = useContext(ViewContext);
  const [loading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [tracked, setTracked] = useState([]);

  const headers = props.headers;
  const setStrategy = props.setStrategy;
  let strategies = {};

  let currentHeader;

  let fakerStrategies = {
    "address": {
      icon: "",
      functions: Object.keys(faker.address).sort(),
      blackList: [],
    },
    "commerce": {
      icon: "",
      functions: Object.keys(faker.commerce).sort(),
      blackList: [],
    },
    "company": {
      icon: "",
      functions: Object.keys(faker.company).sort(),
      blackList: [],
    },
    "datatype": {
      icon: "",
      functions: Object.keys(faker.datatype).sort(),
      blackList: ["array","json"],
    },
    "date": {
      icon: "",
      functions: Object.keys(faker.date).sort(),
      blackList: ["between", "betweens"],
    },
    "finance": {
      icon: "",
      functions: Object.keys(faker.finance).sort(),
      blackList: [],
    },
    "internet": {
      icon: "",
      functions: Object.keys(faker.internet).sort(),
      blackList: [],
    },
    "name": {
      icon: "",
      functions: Object.keys(faker.name).sort(),
      blackList: [],
    },
    "phone": {
      icon: "",
      functions: Object.keys(faker.phone).sort(),
      blackList: [],
    },
    "time": {
      icon: "",
      functions: Object.keys(faker.time).sort(),
      blackList: [],
    },
  }

  let fakerRows = [];

  for (const [key, fakerStrategy] of Object.entries(fakerStrategies)) {
    fakerStrategy.functions.forEach( fakerFunction => {
      if (fakerStrategy.blackList.includes(fakerFunction)) return;
      let example = faker[key][fakerFunction]();
      if (example.length > 23) { example = example.substring(0, 23) + "..."; }
      fakerRows.push({
        "Group": key,
        "Name": fakerFunction,
        "Example": example,
        "Action": <Button small style={{ float: "none", width: "88px" }} action={() => { strategyStateChange(key, fakerFunction); context.modal.hide(false); }} text="Select" />
      });
    });
  }

  function strategyStateChange(fakerGroup, fakerFunction) {
    strategies[currentHeader] = {fakerGroup, fakerFunction};
    createSchema();
    refreshStrategies();
  }

  function ChooseStrategy() {
    let content = <Table
        search
        className='restrict-width'
        data={ fakerRows }
        loading={ loading }
    />

    return content;
  }

  function editStrategy(data){
    ReactGA.modalview('/modal/open');
    context.modal.show({
      title: `Set Strategy for ${headers[data]}`,
      component: ChooseStrategy()
    });
  }

  function createSchema() {
    let tempSchema = [];
    for (const [key, value] of Object.entries(strategies)) {
      let strategy = {
        "redactWith": `${value.fakerGroup}.${value.fakerFunction}`,
        "columnNum": "",
        "columnKey": key,
        "tracked": !!tracked[headers.indexOf(key)]
      };
      tempSchema.push(strategy);
    }

    setStrategy(tempSchema);
  }

  function SetStrategy(props) {
    return <div style={{cursor: "pointer"}} onClick={() => { currentHeader=headers[props.index]; editStrategy(props.index) }}>{props.children}</div>;
  }

  function SetTracked(props) {
    return <Switch required={false} default={!!tracked[props.index]}  onChange={() => { tracked[props.index] = !tracked[props.index]; setTracked(tracked); createSchema(); }} />;
  }

  function refreshStrategies() {
    setTableRows(headers.map( (header) => {
      let content = strategies[header] ? `${strategies[header].fakerGroup}.${strategies[header].fakerFunction}` : `Set Strategy for ${header}`
      if (strategies[header]) content = <span style={{fontWeight: "bold"}}>{content}</span>
      else content = <span style={{color: "#63B76C"}}>{content}</span>
      return {"Header": header, "Action":  <SetStrategy index={headers.indexOf(header)}>{content}</SetStrategy>, "Preserve (See FAQ)": <SetTracked index={headers.indexOf(header)} />}
    }));
  }

  useEffect(() => {
    refreshStrategies();
  }, []);

  return (
      <Table
          search
          className='restrict-width'
          data={ tableRows }
          loading={ loading }
      />
  )
}
