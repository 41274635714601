import {useEffect, useState} from 'react';
import * as Papa from 'papaparse'
import {Animate, Button, Card, Row, Table,} from 'components/lib';
import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {RemovePiiStrategy} from './remove-pii-strategy';
import ReactGA from 'react-ga';

import engine from '../../app/engine'
import faker from  'faker';
export function RemovePii(props) {
  ReactGA.initialize('G-BH07XGSWPG');
  ReactGA.pageview(window.location.pathname + window.location.search);
  const explorerVsFinder = navigator.platform.toLowerCase().includes('mac') === true ? 'Finder' : 'your file explorer';
  const onDrop = useCallback(acceptedFiles => {
    onFileChange(acceptedFiles);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: 'text/csv'})

  const [file, setFile] = useState({selectedFile: null});
  const [rows, setRows] = useState([]);
  const [strategy, setStrategy] = useState([]);
  const [header, setHeader] = useState([]);
  const [progress, setProgress] = useState('awaitingUpload');
  const [loading, setLoading] = useState(true);
  const [oneStrategy, setOneStrategy] = useState(false);


  function setStrategies(data) {
    // console.log(data);
    setOneStrategy(true);
    setStrategy(data);
  }
  useEffect(() => {
    if (strategy.length) processRows()
  }, [strategy]);

  const temp = [];
  const onFileChange = files => {
    Papa.parse(files[0], {
      worker: true,
      header: false,
      step: function (row, parser) {
        setHeader(row.data);
        setProgress('headersSet');
        parser.abort();
      },
      complete: function () {
        setLoading(false);
      }
    });
    setFile({selectedFile: files[0]});
  };

  function processRows() {
    setLoading(true);
    const redactor = engine(faker, { fileType: "csv", columns: strategy });
    Papa.parse(file.selectedFile, {
      worker: true,
      header: false,
      step: function (row) {
        temp.push(redactor.process(row.data));
      },
      complete: function () {
        setLoading(false);
        setRows(temp);
        // console.log(temp)
      }
    });
  }

  function downloadStrategy() {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify({ fileType: "csv", columns: strategy })));
    element.setAttribute('download', 'strategy.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  function downloadTable() {
    ReactGA.event({
      category: 'metric',
      action: 'download_file'

    })
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(Papa.unparse(rows)));
    element.setAttribute('download', 'redacted-pii.csv');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  function DownloadButton(props) {
    if (props.ready) {
      return <Button action={downloadTable} text={"Save"}></Button>;  }  return '';
  }

  function DownloadStrategyButton(props) {
    if (props.ready) {
      return <Button title="Save a reusable template" style={{ marginLeft: '10px'}} action={downloadStrategy} color={'lblue'} text={"Save Strategy"}></Button>;  }  return '';
  }

  function initialState(props) {
    return <div>
      <Card align='center'>
        <Row color='tint' align='center' style={{ border: "1px dashed #000" }} >
          <div {...getRootProps()} style={{cursor : 'pointer'}} >
            <input {...getInputProps()} />
            {
              isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>Drop a file here or click to open {explorerVsFinder}. <br /> Your file is <span style={{fontWeight: "bold", color: "#306380"}}>not</span> sent to our servers; all processing is in-browser.</p>
            }
          </div>
        </Row>
      </Card>
    </div>;
  }

  function hasHeaderState(props) {
    return <div>
      <Table
          className='restrict-width'
          data={ header }
          loading={ loading }
      />
    </div>;
  }

  let content;
  switch (progress) {
    case 'awaitingUpload':
      content = initialState();
      break;
    case 'headersSet':
      content = <RemovePiiStrategy headers={header} setStrategy={setStrategies}></RemovePiiStrategy>;
      break;
    default:
      return null;
  }
  return (
      <Row title=''>
        <Animate>
          {content}
        </Animate>

        <div style={{marginTop: '20px'}}>
          <DownloadButton ready={oneStrategy }></DownloadButton>
          <DownloadStrategyButton ready={oneStrategy}></DownloadStrategyButton>
        </div>
      </Row>
  )
}
