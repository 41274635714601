/***
*
*   FEATURES
*   Feature list for use on home/landing pages
*
**********/

import React from 'react';
import { Grid, Icon } from 'components/lib';
import Style from './features.module.scss';

export function Features(props){

  return(
    <div className={ Style.features }>
      <Grid cols={ 3 }>

        <div>
          <Icon image='eye-off' size={ 16 } />
          <h3>Dynamic Replacement</h3>
          <p>Replace protected data as needed.</p>
        </div>

        <div>
          <Icon image='user-check' size={ 16 } />
          <h3>In-browser</h3>
          <p>No data is sent to our servers.</p>
        </div>

        <div>
          <Icon image='shuffle' size={ 16 } />
          <h3>Flexible</h3>
          <p>Over one hundred data types available.</p>
        </div>

        <div>
          <Icon image='activity' size={ 16 } />
          <h3>Fast</h3>
          <p>Process thousands of records in seconds.</p>
        </div>

        <div>
          <Icon image='refresh-cw' size={ 16 } />
          <h3>Reusable</h3>
          <p>Downloadable templates.</p>
        </div>

        <div>
          <Icon image='cloud-off' size={ 16 } />
          <h3>Offline</h3>
          <p>See FAQ for offline use.</p>
        </div>

      </Grid>
    </div>
  );
}
