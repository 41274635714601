/***
*
*   TABLE
*   Please view the full documentation: https://docs.usegravity.app/ui/table
*
*   PROPS
*   data: array of objects for body and header (optional)
*   search: bool to show the search field
*   sort: allow the table columns to be sorted
*   loading: bool to toggle loading spinner
*   badge - object containing column name and color to add badges to column
*   show - array of columns (object key names) to show (shows all if not provided)
*   hide - array of columns (object key names) to hide
*   actions: object with edit/delete keys set to callback functions (optional)
*
**********/

import React, { Fragment, useState, useEffect } from 'react';
import { Loader, Search } from 'components/lib';
import { Header } from './header';
import { Body } from './body';
import Style from './table.module.scss';

export function Table(props){

  // state
  const [header, setHeader] = useState(null);
  const [body, setBody] = useState(null);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    if (props.data){

      // If set to true we want to use the orde of the 'show' property for displaying columns
      if (props.overrideHeader === true) {
        let header = [];
        for (let key of props.show) {
          header.push({
            name: key,
            title: key.replace('_', ' '),
            sort: key === 'actions' ? false : true
          })
        }
        setBody(props.data);
        setHeader(header);
        return;
      }

      // create the headers
      let header = props.data.header || [];

      if (!header.length){
        for (let key in props.data[0]){
          header.push({

            name: key,
            title: key.replace('_', ' '),
            sort: key === 'actions' ? false : true

          });
        }
      }

      setBody(props.data);
      setHeader(header);

    }
  }, [props.data]);

  // loading
  if (props.loading){
    return (
      <div className={ Style.loading }>
        <Loader />
      </div>
    );
  }

  // no data
  if (!header && !body)
    return false

  function sort(column, direction){

    const rows = filter.length ? [...filter] : [...body];

    rows.sort(function(a,b){

      // fixed bug when value is falsys
      if (typeof a[column] !== 'undefined' && typeof b[column] !== 'undefined'){

        a[column].badge ?
          a = a[column].label : a = a[column];

        b[column].badge ?
          b = b[column].label : b = b[column];

        if (direction === 'desc'){

          if (a > b) return -1;
          if (a < b) return 1;
          else return 0;

        }
        else {

          if (a < b) return -1;
          if (a > b) return 1;
          else return 0;

        }
      }
      else {

        return false;

      }
    });

    filter ? setFilter(rows) : setBody(rows);

  }

  function search(term){

    // search each cell in each row &
    // update state to show only filtered rows
    let rowsToShow = [];

    body.forEach(row => {
      for (let cell in row){
        if (row[cell].toString().toLowerCase().includes(term.toLowerCase())){

          if (!rowsToShow.includes(row))
            rowsToShow.push(row);

        }
      }
    })

    setFilter(rowsToShow);

  }

  function deleteRow(data){

    // call delete prop, then update state
    props.actions.delete(data, () => {

      let state = [...body];
      state.splice(state.findIndex(x => x.id === data.id), 1);
      setBody(state);

    });
  }

  function editRow(data){

    // call edit prop and update row
    props.actions.edit(data, (res) => {

      let state = [...body];
      let row = state[state.findIndex(x => x.id === data.id)];
      Object.keys(res).map(key => row[key] = res[key].value);

    });
  }

  return (
    <Fragment>

      { props.search &&
        <Search className={ Style.search } callback={ search }/> }

      <table className={ !props.naked && Style.table }>

        { header &&
          <Header
            data={ header }
            callback={ sort }
            show={ props.show }
            hide={ props.hide }
            actions={ props.actions }
          />
        }
        { body &&
          <Body
            data={ filter ? filter : body }
            show={ props.show }
            hide={ props.hide }
            badge={ props.badge }
            header={ header }
            actions={{

              edit: props.actions?.edit && editRow,
              delete: props.actions?.delete && deleteRow,
              email: props.actions?.email,
              custom: props.actions?.custom

            }}
          />
        }
      </table>
    </Fragment>
  );
}
