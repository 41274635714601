import {RedactedItem} from "../../lib";
import React from "react";
import faker from 'faker';

export function RedactedHolder(props){

    return (
        <div style={{
            border: "1px solid #000",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            color: "#404040",
            padding: "1em",
            boxShadow: "0 1em 1.25em rgb(0 0 0 / 20%)",
            display: "flex",
            flexWrap: "wrap",
            margin: "2em auto 2em auto",
            fontWeight: "500",
            fontSize: "0.9em",
            textAlign: "left",
            width: "67%",
        }}>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Name: James&nbsp;<RedactedItem>Jones</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Date of Birth:&nbsp;<RedactedItem text="Protected - DOB">February, 3 1981</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Phone #: 555-<RedactedItem>312-6468</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Address: 321&nbsp;<RedactedItem>2nd Ave.</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                State: { faker.address.state() }
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Zip Code:&nbsp;<RedactedItem>12345</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Account #:&nbsp;<RedactedItem text="Protected - Account #">ID-87654321</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Social Security #:&nbsp;<RedactedItem text="Protected - SSN">987-65-4321</RedactedItem>
            </div>

        </div>
    );

}
