import React, {Fragment, useContext, useEffect, useState, Flex} from 'react';
import {
  ViewContext, Card, AccountNav, Table,
  Button, Animate, APIState
} from 'components/lib';

export function Domains(props){

  const context = useContext(ViewContext);
  const [domains, setDomains] = useState({ data: null, loading: false });
  const refreshData = () => APIState(setDomains,  '/api/domain');

  function addDomain(data, callback){

    context.modal.show({
      title: 'Add Domain',
      form: {
        domain: {
          label: 'Domain',
          type: 'text',
          required: true,
          regex: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
          errorMessage: 'Please enter a valid domain name',
        }
      },
      buttonText: 'Add',
      text: 'Enter the domain you wish to add',
      url: '/api/domain',
      method: 'POST'

    }, (res) => {

      context.notification.show('Domain has been added', 'success', true);
      refreshData();
      callback(res);

    });
  }

  function deleteDomain(data, callback){

    context.modal.show({
      title: 'Delete Domain',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete Domain',
      text: 'Are you sure you want to delete ' + data.name + '?',
      url: '/api/domain/' + data.id,
      method: 'DELETE'

    }, () => {

      context.notification.show(data.name + ' was deleted', 'success', true);
      callback();

    });
  }

  useEffect(() => {
    refreshData();
  }, []);

  return (

      <Fragment>
        <AccountNav />
        <Animate>

          <section className='title-row'>
            <h2>Manage Domains</h2>
            <Button small text='Add Domain' action={ addDomain }/>
          </section>

          <Card>
            <Table
                search
                className='restrict-width'
                data={ domains?.data }
                loading={ domains?.loading }
                show={['name', 'date_created', 'verified']}
                badge={{ col: 'verified', color: 'red', condition: [
                    { value: true, color: 'green' },
                  ]}}
                actions={{ delete: deleteDomain }}
            />
          </Card>

        </Animate>
      </Fragment>

  );
}
